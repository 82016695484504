import React, {useState, useEffect} from 'react';
import {BeatLoader} from "react-spinners";
import {useDispatch, useSelector} from "react-redux";
import {getCourseList, getGlobal} from "../../api/app";
import Footer from "../../components/layout/Footer";
import SEO from "../../components/layout/Seo";
import SlideShow from "../../components/layout/SlideShow";

const Explore = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [catCourses, setCatCourses] = useState(null);
    const [title, setTitle] = useState(null);
    const [desc, setDesc] = useState(null);
    const [categoryCourse, setCategoryCourse] = useState([]);

    const {category, courses} = useSelector(state => state.app);

    useEffect(() => {
        dispatch(getGlobal()).then((first_response) => {
            setCategoryCourse(first_response.category_course);
            const randomCategory = category[Math.floor(Math.random() * category.length)];
            setTitle(randomCategory.name);
            setDesc(randomCategory.description);

            getCourseList(randomCategory._id).then((response) => { 
                if (!response.error) {
                    setCatCourses(response.payload);
                    setLoading(false);
                }
            });
        });
    }, []);

    return (
        <div>
            <SEO title="ZStudy | Explore" description="Explore courses at zstudy" />
            {loading ? <div className="h-screen flex item-center justify-center">
                <div className="loader">
                    <BeatLoader color="#003074" size={20} />
                </div>
            </div> : <div>
                <div className="container">
                    <div className="page-spacer"></div>
                    <div className="lg:flex lg:space-x-10">

                        <div className="lg:w-3/12 space-y-4 lg:block hidden">
                            {categoryCourse.map((item) => (
                                <div>
                                    <h4 className="font-semibold text-base"> {item.category_name}</h4>
                                    {item.data.map((data, index) => (
                                        <a href={`#/course/${data._id}`} className="text-truncate uk-text-truncate"> {index + 1}. {data.name}</a>
                                    ))}
                                </div>
                            ))}
                        </div>

                        <div className="w-full md:space-y-10 space-y-5">
                            <div>

                                <div className="grid gap-4 mb-5">
                                    <div className="overflow-hidden">
                                        <SlideShow type={"course"}/>
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <div className="text-xl font-semibold"> Latest and Most Popular Courses </div>
                                    <div className="text-sm mt-2"> Choose from our highly user rated online video courses with new additions published every month.</div>
                                </div>

                                <div className="mt-3">
                                    <h4 className="py-3 border-b font-semibold text-grey-700  mx-1 mb-4" hidden>
                                        <ion-icon name="star"></ion-icon>
                                        Featured today
                                    </h4>

                                    <div className="relative" uk-slider="finite: true">
                                        <div className="uk-slider-container px-1 py-3">
                                            <ul className="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-small uk-grid">
                                                {courses.map((item) => (
                                                    <li>
                                                        <a href={`#/course/${item._id}`} className="uk-link-reset">
                                                            <div className="card uk-transition-toggle">
                                                                <div className="card-media h-40">
                                                                    <div className="card-media-overly"></div>
                                                                    <img src={item.cover.uri} alt="" className=""/>
                                                                    <span className="icon-play"></span>
                                                                </div>
                                                                <div className="card-body p-4">
                                                                    <div className="font-semibold line-clamp-2"> {item.name}</div>
                                                                    <div
                                                                        className="flex space-x-2 items-center text-sm pt-3">
                                                                        <div> {item.category.name}</div>
                                                                    </div>
                                                                    <div className="pt-1 flex items-center justify-between">
                                                                        <div className="text-sm font-medium"> ZStudy</div>
                                                                        <div className="text-md font-semibold"> {item.premium ? "Premium" : "Free"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>

                                            <a className="absolute bg-white top-1/4 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
                                               href="#" uk-slider-item="previous"> <i
                                                className="icon-feather-chevron-left"></i></a>
                                            <a className="absolute bg-white top-1/4 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
                                               href="#" uk-slider-item="next"> <i
                                                className="icon-feather-chevron-right"></i></a>

                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div>

                                <div className="md:flex justify-between items-center mb-8 pt-4 border-t">

                                    <div>
                                        <div className="text-xl font-semibold"> {title}</div>
                                        <div className="text-sm mt-2 font-medium text-gray-500 leading-6"> {desc}</div>
                                    </div>
                                </div>

                                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-5">
                                    {catCourses.map((item) => (
                                        <a href={`#/course/${item._id}`} className="uk-link-reset">
                                            <div className="card uk-transition-toggle">
                                                <div className="card-media h-40">
                                                    <div className="card-media-overly"></div>
                                                    <img src={item.cover.uri} alt="" className=""/>
                                                    <span className="icon-play"></span>
                                                </div>
                                                <div className="card-body p-4">
                                                    <div className="font-semibold line-clamp-2"> {item.name}</div>
                                                    <div
                                                        className="flex space-x-2 items-center text-sm pt-3">
                                                        <div> {item.category.name}</div>
                                                    </div>
                                                    <div className="pt-1 flex items-center justify-between">
                                                        <div className="text-sm font-medium"> ZStudy</div>
                                                        <div className="text-md font-semibold"> {item.premium ? "Premium" : "Free"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <Footer/>
            </div>}
        </div>
    );
};

export default Explore;
