import React, { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { getAllBook } from "../../api/app";
import Footer from "../../components/layout/Footer";
import { truncate } from "../../utils/trunate";
import SEO from "../../components/layout/Seo";
import SlideShow from "../../components/layout/SlideShow";

const Books = () => {
    const [loading, setLoading] = useState(true);
    const [books, setBooks] = useState(null);

    useEffect(() => {
        getAllBook().then((response) => {
            if (!response.error) {
                setBooks(response.payload);
                setLoading(false);
            }
        });
    }, []);

    return (
        <div>
            <SEO
                title="ZStudy | Books"
                description="Check out the varity of books available"
            />
            {loading ? (
                <div className="h-screen flex item-center justify-center">
                    <div className="loader">
                        <BeatLoader color="#003074" size={20} />
                    </div>
                </div>
            ) : (
                <div className="container">
                    <div className="page-spacer"></div>

                    <div className="lg:flex lg:space-x-10">

                        <div className="w-full md:space-y-10 space-y-5">

                            <div className="grid gap-4 mb-5">
                                <div className="overflow-hidden">
                                    <SlideShow type={"book"}/>
                                </div>
                            </div>

                            <div>

                                <div className="mb-2">
                                    <div className="text-xl font-semibold"> Lastest Books</div>
                                    <div className="text-sm mt-2"> Choose from our highly user rated books with new additions published every month.
                                    </div>
                                </div>

                                <div className="mt-3">

                                    <h4 className="py-3 border-b font-semibold text-grey-700  mx-1 mb-4" hidden>
                                        <ion-icon name="star"></ion-icon>
                                        Featured today
                                    </h4>

                                    <div className="relative" uk-slider="finite: true">

                                        <div className="uk-slider-container px-1 py-3">

                                            <ul className="uk-slider-items uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-3 uk-grid-small uk-grid text-sm font-medium text-center">
                                                {books.map((item) => {
                                                    return(
                                                        <li>
                                                            <div className="relative overflow-hidden bg-white shadow md:rounded-lg rounded-md">
                                                                <a href={`#/book/${item._id}`}>
                                                                    <img src={item.cover_uri} alt=""
                                                                         className="w-full h-48 object-cover"/>
                                                                    <div className="p-3 truncate"> {truncate(item.name, 30)}{" "}</div>
                                                                </a>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <a className="absolute bg-white top-1/4 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
                                               href="#" uk-slider-item="previous"> <i
                                                className="icon-feather-chevron-left"></i></a>
                                            <a className="absolute bg-white top-1/4 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
                                               href="#" uk-slider-item="next"> <i
                                                className="icon-feather-chevron-right"></i></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Footer/>
        </div>
    );
};

export default Books;
