import React, {useEffect, useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import {getBanners} from "../../api/app";

const SlideShow = (props) => {

    const [banners, setBanners] = useState([]);

    useEffect(() => {
        getBanners(props.type).then((response) => {
            setBanners(response.banners.map((item) => ({
                src: item.cover.uri,
                alt: item.name,
            })));
        });

        return () => {
            setBanners([]);
        };

    }, []);

    return (
        <Swiper
            modules={[Navigation, Autoplay]}
            navigation
            slidesPerView={1}
            loop={true}
            autoplay={{
                delay: 3000, // 3 seconds
                disableOnInteraction: false // Keeps autoplay even after user interaction
            }}
            className="mb-5"
            autoHeight={true}
            >
            {banners.map((item, index) => (
                <SwiperSlide key={index}>
                    <a href="#" className="relative block w-full lg:h-48 h-40 rounded-md overflow-hidden">
                        <img src={item.src} alt={item.alt} className="absolute w-full h-full object-cover" 
                        style={{
                            objectFit: "fill",
                            objectPosition: "center",
                        }} />
                    </a>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SlideShow;
