import React, {useState, useEffect} from 'react';
import {BeatLoader} from "react-spinners";
import {getAllCourse} from "../../api/app";
import Footer from "../../components/layout/Footer";
import {truncate} from "../../utils/trunate";
import {encodeQueryParameter} from "../../utils/url";
import {useSelector} from "react-redux";
import SEO from '../../components/layout/Seo';

const Courses = () => {

    const auth = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(true);
    const [courses, setCourses] = useState(null);

    useEffect(() => {
        getAllCourse().then((response) => {
            if(!response.error) {
                setCourses(response.payload)
                setLoading(false);
            }
        })
    }, []);

    return (
        <div>
            <SEO title="ZStudy | Courses" description="Lists of Courses" />
            {loading ? <div className="h-screen flex item-center justify-center">
                    <div className="loader">
                        <BeatLoader color="#003074" size={20} />
                    </div>
                </div> :
                <div className="container">
                    <div className="md:flex justify-between items-center mb-8">
                        <div>
                            <div className="text-xl font-semibold">Courses</div>
                            <div className="text-sm mt-2 font-medium text-gray-500 leading-6"> Choose from {courses.length} courses
                                with new additions published every months
                            </div>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5">
                        {courses.map((item) => (
                            <a href={`#/course/${item._id}`} className="uk-link-reset">
                                <div className="card uk-transition-toggle">
                                    <div className="card-media h-40">
                                        <div className="card-media-overly"></div>
                                        <img src={item.cover.uri} alt="" className=""/>
                                        <span className="icon-play"></span>
                                    </div>
                                    <div className="card-body p-4">
                                        <div className="font-semibold line-clamp-2"> {item.name}</div>
                                        <div
                                            className="flex space-x-2 items-center text-sm pt-3">
                                            <div> {item.category.name}</div>
                                        </div>
                                        <div className="pt-1 flex items-center justify-between">
                                            <div className="text-sm font-medium"> ZStudy</div>
                                            <div className="text-md font-semibold"> {item.premium ? "Premium" : "Free"}</div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            }
            <Footer/>
        </div>
    );
};

export default Courses;
