import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../api/auth";
import "simplebar";
import SimpleBar from "simplebar-react";
import formatNumber from "../../utils/formatNumber";
import {REMOVE_FROM_CART} from "../../stores/cart";
import {truncate} from "../../utils/trunate";

const Header = (prop) => {

    const auth = useSelector((state) => state.auth.user);
    const carts = useSelector((state) => state.cart.products);

    const dispatch = useDispatch();

    const [menu, set_menu] = useState({
        explore: "",
        courses: "",
        book: "",
        category: "",
        question: "",
        virtual: "",
        academia: ""
    });

    // //detect url change and update it.
    useEffect(() => {
        let url = window.location.hash.split("/").pop();
        Object.keys(menu).forEach(function(key){ set_menu((prevState) => ({
            ...prevState,
            [key]: "",
            [url]: "active"
        }))});
    }, [window.location.hash.split("/").pop()])

    const subtotal = () => {
        return carts.reduce(function (result, item) {
            return result + item.ori_price * item.quantity;
        }, 0);
    }

    const onLogoutClick = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
    };

    const removeItemCart = (id) => {
        dispatch(REMOVE_FROM_CART({_id: id}))
    }

    return (
        <div>
            <header uk-sticky="border-b">
                <div className="header_inner">
                    <div className="left-side">
                        <a id="logo" href="#/">
                            <img src="/assets/images/ed9d10d6-3fa1-4e02-8443-a34f05979d1b.png" alt="logo" style={{ height: 50 }} />
                        </a>
                    </div>

                    <div className="center-side" style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <div className="search-bar" style={{ width: '100%', maxWidth: '600px' }}>
                            <input
                                type="text"
                                placeholder="Search for courses, books, and more..."
                                className="search-input"
                                style={{ width: '100%', padding: '10px', borderRadius: '8px', border: '1px solid #ccc' }}
                            />
                        </div>
                    </div>

                    <div className="right-side">
                        {auth.isAuthenticated ? (
                            <>
                                <a href="#" className="header_widgets">
                                    <ion-icon name="cart-outline" className="is-icon"></ion-icon>
                                </a>
                                <div uk-drop="mode: click" className="dropdown_cart">
                                    <div className="cart-headline">
                                        My Cart <a href="#/cart" className="checkout">View All</a>
                                    </div>
                                    {carts.length > 0 ? (
                                        <ul className="dropdown_cart_scrollbar">
                                            {carts.map((item) => (
                                                <li key={item._id}>
                                                    <div className="cart_avatar">
                                                        <img src={item.img} alt="" className="object-contain" />
                                                    </div>
                                                    <div className="cart_text">
                                                        <h4>{truncate(item.name, 20)}</h4>
                                                        <p>K{formatNumber(item.ori_price)} x {item.quantity}</p>
                                                    </div>
                                                    <div className="cart_price">
                                                        <span>K{item.ori_price * item.quantity}</span>
                                                        <button className="type" onClick={() => removeItemCart(item._id)}>Remove</button>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className="text-center p-5">The cart is empty. You do not have any cart yet.</p>
                                    )}
                                    <div className="cart_footer">
                                        <h2>Total Items: {carts.length}</h2>
                                        <h2><strong>Total: K{subtotal()}</strong></h2>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {auth.isAuthenticated ? (
                            <div>
                                <a href="#">
                                    <img src="../assets/images/avatars/placeholder.png" className="header_widgets_avatar" alt="" />
                                </a>
                                <div uk-drop="mode: click;offset:5" className="header_dropdown profile_dropdown">
                                    <ul>
                                        <li>
                                            <a href="#" className="user">
                                                <div className="user_avatar">
                                                    <img src="assets/images/avatars/placeholder.png" alt="" />
                                                </div>
                                                <div className="user_name">
                                                    <div>{auth.user.firstName ? `${auth.user.firstName} ${auth.user.lastName}` : "No Name"}</div>
                                                    <span>{auth.user.email}</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li><hr /></li>
                                        <li><a href="#/order">
                                            <ion-icon name="bag-outline" className="is-icon"></ion-icon>
                                            Orders</a></li>
                                        <li><a href="#/account">
                                            <ion-icon name="settings-outline" className="is-icon"></ion-icon>
                                            Account Settings</a></li>
                                        <li><hr /></li>
                                        <li>
                                            <Link to="#" onClick={onLogoutClick}>
                                                <ion-icon name="log-out-outline" className="is-icon"></ion-icon>
                                                Log Out
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Link to="/login" className="bg-blue-500 blue-500 px-6 py-3 rounded-md shadow text-white">Login</Link>
                            </div>
                        )}
                    </div>
                </div>
            </header>

            <div className="container">
                <nav className="header_menu">
                    <ul>
                        <li><a href="#/courses" className={menu.courses}> Courses </a></li>
                        <li><a href="#/books" className={menu.category}> Books </a></li>
                        <li><a href="#/products" className={menu.courses}> STEM Kits </a></li>
                        <li><a href="#/virtual" className={menu.virtual}> Virtual Lab </a></li>
                        {auth.isAuthenticated ? <li><a href="#/question" className={menu.question}> Ask Question</a></li> : null}
                    </ul>
                </nav>

                <div className="overly" uk-toggle="target: .header_menu ; cls: is-visible"></div>
            </div>
        </div>
    );
};

export default Header;
