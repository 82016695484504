import React, {useState, useEffect} from 'react';
import {BeatLoader} from "react-spinners";
import {getVirtualLab} from "../../api/app";
import Footer from "../../components/layout/Footer";
import {useSelector} from "react-redux";
import SEO from '../../components/layout/Seo';
import SlideShow from "../../components/layout/SlideShow";

const Virtual = () => {

    const auth = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(true);
    const [virtual, setVirtual] = useState(null);

    useEffect(() => {
        getVirtualLab().then((response) => {
            if(!response.error) {
                console.log(response.payload);
                setVirtual(response.payload)
                setLoading(false);
            }
        })
    }, []);

    return (
        <div>
            <SEO title="ZStudy | Courses" description="Lists of Courses" />
            {loading ? <div className="h-screen flex item-center justify-center">
                    <div className="loader">
                        <BeatLoader color="#003074" size={20} />
                    </div>
                </div> :
                <div className="container">
                    <div className="grid gap-4 mb-5">
                        <div className="overflow-hidden">
                            <SlideShow type={"virtual"}/>
                        </div>
                    </div>
                    <div className="md:flex justify-between items-center mb-8">
                        <div>
                            <div className="text-xl font-semibold">Virtual Labs</div>
                            <div className="text-sm mt-2 font-medium text-gray-500 leading-6"> Choose from {virtual.length} virtual labs with new additions published every months
                            </div>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5">
                        {virtual.map((item) => (
                            <a href={item.file.uri} target="_blank" className="uk-link-reset">
                                <div className="card uk-transition-toggle">
                                    <div className="card-media h-40">
                                        <div className="card-media-overly"></div>
                                        <img src={item.cover.uri} alt="" className=""/>
                                        <span className="icon-play"></span>
                                    </div>
                                    <div className="card-body p-4">
                                        <div className="font-semibold line-clamp-2"> {item.name}</div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            }
            <Footer/>
        </div>
    );
};

export default Virtual;
